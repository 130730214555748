import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import Container from '../../components/container'
import ProductsSubnav from '../../components/products_subnav'
import Seo from '../../components/seo'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import EsTeaser from '../../components/es_teaser'

const ProductUpdatesPage = ({ data }) => {
  
  const content = data.contentfulPage
  const items = data.allContentfulNewItem.nodes

  return (
    <Layout activeNav={true}>
      <Seo
        title={content.seoTitle || content.title}
        description={content.metaDescription || content.subtitle}
      />
      <div className='flex flex-col justify-center relative bg-black text-white'>
        <StaticImage
          src='../../images/productupdates_hero_bg.jpg'
          alt=''
          quality={100}
          placeholder='blurred'
          className='absolute inset-0'
        />
        <Container className='relative pb-4 pt-5 sm:pb-5'>
          <h1 className='text-4vw font-semi-bold'>
            {content.title}
          </h1>
          <p className='text-silver text-md sm:text-lg' style={{ maxWidth: '40em' }}>
            {content.subtitle}
          </p>
        </Container>
      </div>
      <ProductsSubnav />
      <div className='pt-4 sm:pt-5'>
        {items.map((item, index) =>
          <div
            key={`ProductUpdate ${item.title}`}
            className='Section Section--condensed pt-0'>
            <Container size='lg'>
              <div className='grid sm:grid-cols-2 items-center sm:gap-5'>
                <div className='order-first sm:order-none'>
                  <div className='font-semi-bold uppercase text-gray mb-2 leading-tight tracking-wide'>
                    {item.tag}
                  </div>
                  <h2 className='text-xl sm:text-2xl font-regular my-0'>
                    {item.title}
                  </h2>
                  <div className='font-semi-bold uppercase text-gray mt-2 mb-3 leading-tight tracking-wide'>
                    {item.date}
                  </div>
                  <div className='Section-text'>
                    {renderRichText(item.text)}
                  </div>
                </div>
                <div className={index % 2 === 1 ? 'order-first' : null}>
                  {item.image &&
                    <GatsbyImage
                      image={item.image.gatsbyImageData}
                      alt=''
                    />
                  }
                </div>
              </div>
            </Container>
          </div>
        )}
      </div>
      <EsTeaser />
    </Layout>
  )
}

export default ProductUpdatesPage

export const pageQuery = graphql`
  query {
    contentfulPage(url: {eq: "product_updates"}) {
      title
      seoTitle
      metaDescription
      subtitle
    }
    allContentfulNewItem(sort: {fields: date, order: DESC}) {
      nodes {
        title
        tag
        date(formatString: "MMMM YYYY")
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
        text {
          raw
        }
      }
    }
  }
`
